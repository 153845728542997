@import "../../GlobalStyles/mixins.scss";
@import "../../GlobalStyles/variables.scss";

.dashboard {
  &_container {
    padding: 40px;
  }
  &_heading {
    display: flex;
    justify-content: space-between;
    &_left {
      h1 {
        font-weight: 600;
      }
    }
    &_right {
      display: flex;
      align-items: center;
      h3 {
        font-weight: 500;
        cursor: pointer;
      }
    }
  }
  &_sections {
    &_top {
      padding: 16px 0;
      @include FlexJCenter;
      gap: 20px;
      &_cards {
        width: 75%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 24px;
      }
      &_chart {
        width: 25%;
        background: #f7f9fb;
        border-radius: 15px;
        display: flex;
        padding: 20px 0;
        div {
          width: 100% !important;
        }
      }
    }
    &_center {
      display: flex;
      justify-content: space-between;
      &_left {
        width: 48%;
        background: #f7f9fb;
        border-radius: 15px;
        padding: 16px;
      }
      &_right {
        width: 49%;
        background: #f7f9fb;
        padding: 20px;
        border-radius: 15px;
      }
    }
    &_bottom {
      padding: 16px;
      background: #f7f9fb;
      border-radius: 15px;
      margin-top: 20px;
    }
  }
}

@media screen and (max-width: 500px) {
  .dashboard {
    &_container {
      padding: 30px 0;
    }
    &_heading {
      display: flex;
      flex-direction: column;
      &_left {
        h1 {
          font-weight: 600;
        }
      }
      &_right {
        margin-top: 10px;
        display: flex;
        align-items: start;
        flex-direction: column;
        gap: 10px;
      }
    }
    &_sections {
      &_top {
        flex-direction: column;
        &_cards {
          width: 100%;
          grid-template-columns: repeat(1, 1fr);
          gap: 14px;
        }
        &_chart {
          width: 100%;
          div {
            width: 100% !important;
          }
          // .dashboard-chart {
          //   width: 100% !important;
          //   svg {
          //     width: 100% !important;
          //   }
          // }
        }
      }
      &_center {
        flex-direction: column;
        gap: 20px;
        &_left {
          width: 100%;
        }
        &_right {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (min-width: 501px) and (max-width: 1080px) {
  .dashboard {
    &_container {
      padding: 30px 0;
    }
    &_heading {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &_left {
        h1 {
          font-weight: 600;
        }
      }
      &_right {
        display: flex;
        align-items: center;
        margin-top: 10px;
        h3 {
          font-weight: 500;
          cursor: pointer;
        }
      }
    }
    &_sections {
      &_top {
        flex-direction: column;
        &_cards {
          width: 100%;
          grid-template-columns: repeat(2, 1fr);
          gap: 14px;
        }
        &_chart {
          width: 100%;
          div {
            width: 100% !important;
          }
        }
      }
      &_center {
        flex-direction: column;
        gap: 20px;
        &_left {
          width: 100%;
        }
        &_right {
          width: 100%;
        }
      }
    }
  }
}
