@mixin Relative {
  position: relative;
  width: 100%;
}

@mixin RelativeFlex {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin FlexColumnCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@mixin FlexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin FlexJBetween {
  display: flex;
  justify-content: space-between;
}

@mixin FlexColumnJBetween {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@mixin FlexJAround {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

@mixin FlexJCenter {
  display: flex;
  justify-content: center;
}

@mixin FlexJStart {
  display: flex;
  justify-content: flex-start;
}

@mixin FlexJEnd {
  display: flex;
  justify-content: flex-end;
}

@mixin FlexColumnAEnd {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

@mixin FlexAcenter {
  display: flex;
  align-items: center;
}

@mixin FlexWrap {
  display: flex;
  flex-wrap: wrap;
}

@mixin GradientBackground {
  background-image: linear-gradient(
    to right,
    $alizarin_crimson,
    $tamarillo,
    $cab_sav
  );
}

@mixin textc {
  text-align: center;
}

// padding and margin none

@mixin marpad {
  margin: 0;
  padding: 0;
}

// none to display

@mixin displaynone {
  display: none;
}

@mixin listnone {
  list-style-type: none;
}

@mixin textnone {
  text-decoration: none;
}
