@import "../../GlobalStyles/mixins.scss";
@import "../../GlobalStyles/variables.scss";

.dashboard-card {
  border-radius: 16px;
  &_container {
    padding: 24px;
  }
  &_heading {
    h4 {
      font-size: $font_xsmall;
      line-height: 20px;
    }
  }
  &_section {
    padding: 8px 0 0 0;
    @include FlexJBetween;
    align-items: center;
    &_left {
      h3 {
        font-size: $font_xlarge;
        font-weight: 600;
        line-height: 36px;
        letter-spacing: 0.7px;
      }
    }
    &_right {
      @include FlexAcenter;
      h6 {
        font-size: $font_xsmall;
        line-height: 20px;
        padding: 0 4px 0 0;
      }
    }
  }
}
