@import "../../GlobalStyles/variables.scss";
@import "../../GlobalStyles/mixins.scss";

.content {
  &_center {
    padding: 12px;
    height: calc(100vh - 60px);
    background-color: #fff;
    border-radius: 5px;
    overflow-y: auto;
  }
}
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 99999;
}
.sidebar {
  background-color: $primary_bg !important;
  height: calc(100vh - 60px);
  transition: width 0.3s ease;
  &_main {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 40px 0 25px 12px;
    cursor: pointer;
    img {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      cursor: pointer;
    }
    h3 {
      color: #000;
      font-size: 14px;
      font-weight: 400;
    }
  }
  ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light.css-dev-only-do-not-override-3rel02 {
    border: none !important;
  }
  ul {
    background-color: $primary_bg !important;
  }
  li.ant-menu-item {
    color: #000 !important;
    // gap: 10px;
  }
  li.ant-menu-item.ant-menu-item-selected {
    color: #000 !important;
    // background: linear-gradient(135deg, #830085 30%, #FA4300) !important;
    background: #d3d3d3 !important;
  }
  .ant-layout-sider-children {
    overflow-y: scroll !important;
    padding-bottom: 30px !important;
  }
}
.custom-menu-item {
  .ant-menu-item,
  .ant-menu-submenu-title {
    display: flex;
    align-items: center;
    // gap: 10px;
    color: #000 !important;
  }
}

.header {
  display: flex;
  justify-content: center;
  background: #0d082c !important;
  line-height: 0 !important;
  align-items: center;
  // border-radius: 12px 12px 0 0;
  &_sec {
    width: 96%;
    line-height: 0 !important;
    &_cont {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &_left {
        display: flex;
        align-items: center;
        &_logo {
          img {
            width: 150px;
            cursor: pointer;
          }
        }
        ul {
          display: flex;
          gap: 30px;
          li {
            color: #fff;
            font-size: 14px;
            cursor: pointer;
          }
        }
      }
      &_right {
        display: flex;
        align-items: center;
        gap: 20px;
        &_search {
          position: relative;
          input {
            font-size: 14px;
            color: rgba(255, 255, 255, 0.4);
            padding: 5px 8px 8px 30px;
            border-radius: 8px;
            width: 160px;
            background-color: #424242;
            border: none;
            &:focus {
              border: none;
            }
          }
          .searchicon {
            position: absolute;
            top: 7px;
            left: 8px;
          }
        }
        &_icons {
          display: flex;
          gap: 15px;
          align-items: center;
          .theme {
            cursor: pointer;
          }
          .time {
            cursor: pointer;
          }
          .notification {
            cursor: pointer;
          }
        }
      }
    }
  }
}

span.ant-layout-sider-zero-width-trigger.ant-layout-sider-zero-width-trigger-left {
  top: 6px !important;
  border-radius: 6px !important;
  width: 30px;
  height: 30px;
  z-index: 9999;
}

@media screen and (max-width: 768px) {
  .header {
    display: flex;
    justify-content: center;
    background: #0d082c !important;
    line-height: 0 !important;
    align-items: center;
    // border-radius: 12px 12px 0 0;
    &_sec {
      width: 96%;
      line-height: 0 !important;
      &_cont {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &_left {
          display: flex;
          align-items: center;
          &_logo {
            img {
              width: 150px;
              cursor: pointer;
            }
          }
          ul {
            display: none;
            gap: 30px;
            li {
              color: #fff;
              font-size: 14px;
              cursor: pointer;
            }
          }
        }
        &_right {
          display: flex;
          align-items: center;
          gap: 20px;
          &_search {
            display: none;
          }
          &_icons {
            display: flex;
            gap: 20px;
            align-items: center;
            margin-right: 20px;
            .theme {
              cursor: pointer;
            }
            .time {
              cursor: pointer;
            }
            .notification {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
