// color
$primary_white: #ffffff;
$primary_black: #000000;
$primary_bg: #f7f9fb;
$header_bg: #0D082C;
$primary_text: #1c1c1c;
$sidebar_active_bg: #1c1c1c0d;


// font size
$font_xxlarge: 32px;
$font_xlarge: 24px;
$font_large: 20px;
$font_medium: 18px;
$font_xmedium: 16px;
$font_small: 14px;
$font_xsmall: 12px;
$font_verysmall: 10px;
$font_xverysmall: 8px;