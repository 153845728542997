.custom-button {
  border: none;
  padding: 10px 25px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.primary {
  background-color: #7e04d5;
  color: #ffffff;
  border-radius: 12px;
  padding: 10px 16px;
  width: 100%;
  font-size: 18px;
  font-weight: 400;
}

.secondary {
  background-color: #ffffff;
  color: #7e04d5;
  border-radius: 12px;
  padding: 8px 16px;
  width: 100%;
  font-size: 18px;
  font-weight: 400;
  border: 2px solid #7e04d5;
}

.google {
  background-color: #f3f9fa;
  color: #313957;
  border-radius: 12px;
  padding: 12px 0px;
  width: 100%;
  font-size: 18px;
  font-weight: 400;
}

.small {
  width: fit-content;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  background-color: #7e04d5;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}

.small2 {
  width: fit-content;
  background-color: #ffffff;
  color: #7e04d5;
  border-radius: 4px;
  border: 2px solid #7e04d5;
  padding: 3px 10px;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}
