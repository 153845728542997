.ads {
  margin: 10px 10px 10px 10px;
  &_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &_left {
      h2 {
        font-size: 20px;
        font-weight: 600;
      }
    }
    &_right {
      display: flex;
      align-items: center;
      gap: 10px;
      .pop {
        padding: 18px;
      }
    }
  }
  &_section {
    margin-top: 20px;
  }
}
.react-resizable-handle {
  bottom: 0;
  cursor: ew-resize;
  display: grid;
  height: 100%;
  place-content: center;
  position: absolute;
  right: -5px;
  width: 10px;
  z-index: 1;
}

@media (max-width: 767px) {
  .ads {
    margin: 40px 0 0;
    &_top {
      flex-direction: column;
      justify-content: flex-start;
      &_left {
        width: 100%;
        h2 {
          font-size: 20px;
          font-weight: 600;
        }
      }
      &_right {
        flex-direction: column;
        justify-content: flex-start;
        gap: 10px;
        .pop {
          padding: 18px;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .ads {
    margin: 40px 10px 10px 10px;
  }
}
