.forgot-password {
    width: 100%;

    &_cont {
        background: #fff;
        padding: 30px 30px 30px 0;

        &_sec {
            display: flex;
            height: 90vh;
            justify-content: space-between;
            width: 100%;

            &_left {
                width: 48%;

                display: flex;
                flex-direction: column;
                justify-content: center;

                &_logo {
                    display: flex;
                    justify-content: flex-start;

                    img {
                        width: 250px;
                        height: auto;
                    }
                }

                &_content {
                    padding-left: 50px;

                    &_forgot {
                        width: 70%;
                        text-align: end;
                        margin-bottom: 15px;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;

                        svg {
                            cursor: pointer;
                        }

                        span {
                            color: #1e4ae9;
                            font-weight: 400;
                            font-size: 16px;
                            cursor: pointer;
                            margin: 0 0 0 5px;
                        }
                    }

                    &_head {
                        width: 90%;
                        margin-bottom: 20px;

                        h1 {
                            font-weight: 600;
                            font-size: 36px;
                            color: #0c1421;
                            margin-bottom: 15px;
                        }

                        p {
                            color: #313957;
                            font-size: 20px;
                            font-weight: 400;
                        }
                    }

                    &_data {
                        width: 70%;
                        margin-bottom: 20px;

                        &_input {
                            width: 100%;
                            margin-bottom: 20px;
                        }
                    }

                    &_button {
                        width: 70%;
                        margin-bottom: 15px;
                    }

                    &_copy {
                        width: 70%;
                        text-align: center;

                        p {
                            font-weight: 400;
                            font-size: 16px;
                            color: #959cb6;
                        }
                    }
                }
            }

            &_right {
                // height: 90vh;
                width: 48%;
                background-image: url("../../Assets//Images/Login-img.png");
                border-radius: 20px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .forgot-password {
        width: 100%;

        &_cont {
            padding: 30px 0;

            &_sec {
                &_left {
                    width: 100%;

                    &_logo {
                        display: flex;
                        justify-content: center;
                        margin-bottom: 20px;

                        img {
                            width: 250px;
                            height: auto;
                            margin-right: 30px;
                        }
                    }

                    &_content {
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                        align-items: center;
                        padding-left: 0;

                        &_head {
                            width: 90%;

                            h1 {
                                font-weight: 600;
                                font-size: 24px;
                                margin-bottom: 12px;
                            }

                            p {
                                font-size: 16px;
                            }
                        }

                        &_data {
                            width: 90%;
                        }

                        &_forgot {
                            width: 90%;
                        }

                        &_button {
                            width: 90%;
                        }

                        &_copy {
                            width: 90%;
                        }
                    }
                }

                &_right {
                    display: none;
                }
            }
        }
    }
}