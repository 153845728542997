.acc {
    &_section {
      margin-top: 20px;
    }
  }
  .react-resizable-handle {
    bottom: 0;
    cursor: ew-resize;
    display: grid;
    height: 100%;
    place-content: center;
    position: absolute;
    right: -5px;
    width: 10px;
    z-index: 1;
  }
  