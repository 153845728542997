.domain {
  padding: 30px 10px;
  &_header {
    text-align: left;
    margin-bottom: 10px;
    h2 {
      font-weight: 600;
      font-size: 16px;
      color: #0e2647;
    }
    span {
      font-weight: 600;
      font-size: 16px;
      color: #0e2647;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  &_section {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #e7e9ed;
    &_left {
      background: #fff;
      border-right: 1px solid #e7e9ed;
      display: flex;
      flex-direction: column;
      min-width: 250px;
      padding: 0;
      width: 20%;
      &_top {
        display: flex;
        flex-direction: column;
        padding-bottom: 10px;
        img {
          width: 100px;
          height: 80px;
        }
        a {
          font-weight: 600;
          font-size: 18px;
          color: #0e2647;
          cursor: pointer;
          word-break: break-word;
              overflow-wrap: break-word;
        }
        a:hover {
          text-decoration: underline;
        }
      }
      &_center {
        max-height: calc(100vh - 200px);
        overflow-y: scroll;
        &_card {
          padding: 6px;
          margin-bottom: 5px;
          p {
            color: #8692a3;
            font-weight: 400;
            font-size: 12px;
            overflow-wrap: break-word;
          }
          span {
            font-size: 14px;
            color: #0e2647;
            font-weight: 400;
            overflow-wrap: break-word;
          }
        }
        &_card:hover {
          background-color: #f5f6f8;
        }
      }
    }
    &_right {
      width: 73%;
      .timeline {
        &_header {
          background: #f5f6f8;
          position: sticky;
          top: 0;
          z-index: 10;
          h3 {
            color: rgba(0, 0, 0, 0.651);
            font-weight: 600;
            padding: 12px;
            text-align: left;
            font-size: 14px;
          }
        }
        &_details {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: #fff;
          padding: 12px;
          border-bottom: 1px solid #ddd;
          p {
            color: rgba(0, 0, 0, 0.4);
            font-size: 12px;
            font-weight: 500;
          }
          span {
            color: rgba(0, 0, 0, 0.4);
            font-size: 12px;
            font-weight: 500;
          }
          &_view {
            width: 20%;
            display: flex;
            gap: 5px;
            align-items: center;
            cursor: pointer;
            p {
              color: rgba(0, 0, 0, 0.4);
              font-size: 14px;
              font-weight: 600;
              word-break: break-word;
              overflow-wrap: break-word;
            }
          }
        }
        &_container {
          max-height: 70vh;
          overflow-y: auto;
          padding-right: 10px;
        }
        &_section {
          margin-bottom: 10px;
        }
      }
      .overview {
        padding: 10px;
        border-radius: 4px;
        &_img {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 70%;
            height: auto;
            object-fit: contain;
          }
        }
      }
    }
  }
}

.ant-modal-title {
  font-size: 20px !important;
  color: #000 !important;
  margin-bottom: 20px !important;
}

.modal-birdview {
  width: 350px;
  h2 {
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: 600;
    color: #000000;
  }
  p {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: 500;
    color: #000000a6;
  }
}

.drawer {
  &_header {
    h2 {
      font-size: 20px;
      color: #000;
      margin-bottom: 20px;
      font-weight: 600;
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }
  &_event {
    p {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      margin-bottom: 25px;
      font-weight: 500;
      color: #000000a6;
    }
  }
}

.content {
  margin-bottom: 20px;
}

.activity-table {
  margin-top: 20px;
}
