.icp {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  &_heading {
    width: 100%;
    display: flex;
    justify-content: start;
    padding: 25px 0 0 0;
    h1 {
      font-size: 18px;
      margin-top: 5px;
      font-weight: 600;
      margin-left: 10px;
    }
  }
  &_top {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-bottom: 2px solid #dddddd;
    padding: 10px 25px 0 25px;
    &_name{
      margin-bottom: 25px;
    }
  }
  &_cont {
    margin-top: 20px;
    &_form {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 0 30px;
      &_filds{
        margin-bottom: 25px;
      }
    }
  }
  &_btns {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    gap: 30px;
  }
  label {
    font-size: 16px;
    font-weight: 600;
  }
  .selects {
    margin: 5px 0 0 0;
    width: 100%;
  }
}
