@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import "./App/GlobalStyles/variables.scss";


body{
  height: 100vh;
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  font-family: "Inter", system-ui;
  font-size: 14px;
  font-weight: 400;
}

.error{
  font-size: 10px;
  color: red;
}

button,
a,
span {
  font-family: "Inter", system-ui;
  font-size: 14px;
  font-weight: 400;
}

ul{
  li{
    list-style-type: none;
    font-family: "Inter", system-ui;
  }
}
body {
  margin: 0;
  font-family: "Inter", system-ui;
}

::-webkit-scrollbar {
  width: 0 !important;
  height: 0px !important;
}
::-webkit-scrollbar-thumb {
  background: $primary_black;
  border-radius: 3px;
}
::-webkit-scrollbar-thumb:hover {
  background: #a8adaf;
}
::-webkit-scrollbar-track {
  border-radius: 5px;
}

.ant-table-body {
  scrollbar-width: thin !important;
}

.acc_section_table .ant-table-header {
  position: relative;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.ant-table-wrapper {
  overflow: hidden;
}

.ant-table {
  overflow: auto;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: #FFF !important;
}

.ant-tabs-tab{
  font-weight: 600 !important;
}
.ant-tabs-tab:hover{
  color: #000 !important;
}