.modal-title {
    text-align: center;
    width: 100%;
  }
  
  .modal-footer {
    display: flex;
  }
  
  .modal-footer-1 {
    justify-content: center; 
  }
  
  .modal-footer-2 {
    justify-content: space-between; 
  }
  