.login {
  width: 100%;
  &_cont {
    background: #fff;
    padding: 30px 30px 30px 0;
    &_sec {
      display: flex;
      justify-content: space-between;
      height: 90vh;
      &_left {
        width: 48%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        
        &_logo {
          display: flex;
          justify-content: flex-start;
          img {
            width: 250px;
            height: auto;
          }
        }
        &_content {
          padding-left: 50px;
          &_head {
            width: 90%;
            margin-bottom: 20px;
            h1 {
              font-weight: 600;
              font-size: 36px;
              color: #0c1421;
              margin-bottom: 15px;
            }
            p {
              color: #313957;
              font-size: 20px;
              font-weight: 400;
            }
          }
          &_data {
            width: 70%;
            margin-bottom: 20px;
            &_input {
              width: 100%;
              margin-bottom: 20px;
            }
          }
          &_forgot {
            width: 70%;
            text-align: end;
            margin-bottom: 15px;
            span {
              color: #1e4ae9;
              font-weight: 400;
              font-size: 16px;
              cursor: pointer;
            }
          }
          &_button {
            width: 70%;
            margin-bottom: 15px;
          }
          &_lines {
            width: 70%;
            margin-bottom: 15px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .line {
              border-top: 1px solid #cfdfe2;
              width: 44%;
            }
            p {
              font-weight: 400;
              font-size: 16px;
              color: #294957;
            }
          }
          &_google {
            width: 70%;
            margin-bottom: 15px;
          }
          &_last {
            width: 70%;
            text-align: center;
            margin-bottom: 100px;
            p {
              font-weight: 400;
              font-size: 18px;
              color: #313957;
              span {
                font-weight: 400;
                font-size: 18px;
                color: #1e4ae9;
              }
            }
          }
          &_copy {
            width: 70%;
            text-align: center;
            p {
              font-weight: 400;
              font-size: 16px;
              color: #959cb6;
            }
          }
        }
      }
      &_right {
        width: 48%;
        background-image: url("../../Assets//Images/Login-img.png");
        border-radius: 20px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .login {
    width: 100%;
    &_cont {
      padding: 30px 0;
      &_sec {
        &_left {
          width: 100%;
          &_logo {
            display: flex;
            justify-content: center;
            margin-bottom: 20px;
            img {
              width: 250px;
              height: auto;
              margin-right: 30px;
            }
          }
          &_content {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            padding-left: 0;
            &_head {
              width: 90%;
              h1 {
                font-weight: 600;
                font-size: 24px;
                margin-bottom: 12px;
              }
              p {
                font-size: 16px;
              }
            }
            &_data {
              width: 90%;
            }
            &_forgot {
              width: 90%;
            }
            &_button {
              width: 90%;
            }
            &_lines {
              width: 90%;
              p {
                font-weight: 400;
                font-size: 16px;
                color: #294957;
              }
            }
            &_google {
              width: 90%;
            }
            &_last {
              width: 90%;
            }
            &_copy {
              width: 90%;
            }
          }
        }
        &_right {
          display: none;
        }
      }
    }
  }
}
