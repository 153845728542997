.icp {
  width: 100%;
  &_cont {
    width: 95%;
    &_btn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0 20px;
      h2 {
        font-size: 18px;
        font-weight: 600;
      }
      &_right {
        display: flex;
        gap: 20px;
      }
    }
  }
}
