.profile {
  &_cont {
    width: 100%;
    padding: 35px 10px 10px;
    &_header {
      h2 {
        font-size: 16px;
        font-weight: 500;
        text-align: start;
      }
    }
    &_sec {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      box-shadow: 0 0 2px 2px #c3c3c3;
      border-radius: 14px;
      padding: 15px;
      margin-top: 10px;
      &_header {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 10px;
        &_left {
          display: flex;
          flex-direction: column;
          align-items: center;
          &_photo {
            margin-bottom: 10px;
            img {
              width: 80px;
              height: auto;
            }
          }
          &_name {
            h3 {
              font-size: 26px;
              font-weight: 600;
            }
          }
        }
        &_right {
          width: fit-content;
          display: flex;
          align-items: center;
          gap: 10px;
        }
      }
      &_data {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        margin-top: 20px;
        &_left {
          &_inputs {
            margin-bottom: 10px;
          }
        }
        &_right {
          &_inputs {
            margin-bottom: 10px;
          }
        }
      }
      &_last {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}
.form_inputs {
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .profile {
    &_cont {
        &_header {
            h2 {
              font-size: 16px;
              font-weight: 500;
              text-align: center;
            }
          }
      &_sec {
        &_data {
          width: 100%;
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          gap: 20px;
          margin-top: 20px;
          &_left {
            &_inputs {
              margin-bottom: 10px;
            }
          }
          &_right {
            &_inputs {
              margin-bottom: 20px;
            }
          }
        }
        &_last {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
}
